<template>
  <div>
    <aad-title>All Handouts</aad-title>
    <aad-loader
      v-if="
        allSessions == undefined ||
        allSessions.length == 0 ||
        allSpeakers == undefined ||
        allSpeakers.length == 0
      "
    ></aad-loader>

    <div v-else>
      <a
        href="#"
        class="btn btn-primary-solid"
        @click="toggleHandoutSessionView"
      >
        <span v-if="isHandoutSessionView">View Speaker Handouts</span>
        <span v-else>View Session Handouts</span>
      </a>
      <!-- Session handouts -->
      <transition name="fade">
        <div v-if="isHandoutSessionView">
          <div v-for="session in allSessions" :key="session.id">
            <div class="meeting-app-info-block w-100">
              <div class="meeting-app-info-header row">
                <router-link
                  :to="{
                    name: 'SessionDetails',
                    params: { id: session.id },
                  }"
                  class="text-link-light-bg"
                >
                  <h4>{{ session.SessionCode }} - {{ session.Name }}</h4>
                </router-link>
              </div>
              <div
                class="meeting-app-info-body"
                v-if="session.Handouts && session.Handouts.length > 0"
              >
                <!-- List out cards for the hadnouts -->
                <div class="card-deck" id="card-deck-vert-xs-full">
                  <div
                    class="card img-vert-xs"
                    v-for="handout in session.Handouts"
                    :key="handout.HandoutTitle"
                  >
                    <handout-card :handout="handout"></handout-card>
                  </div>
                </div>
              </div>
              <div class="meeting-app-info-body" v-else>
                <p>There are no handouts for this session.</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- Speaker Handouts -->

      <transition name="fade">
        <div v-if="!isHandoutSessionView">
          <div v-for="speaker in allSpeakers" :key="speaker.id">
            <div
              class="meeting-app-info-block w-100"
              v-if="speaker.Handouts && speaker.Handouts.length > 0"
            >
              <div class="meeting-app-info-header row">
                <router-link
                  :to="{
                    name: 'SpeakerDetails',
                    params: { id: speaker.id },
                  }"
                  class="text-link-light-bg"
                >
                  <h4>{{ speaker.DisplayName }}</h4>
                </router-link>
              </div>
              <div class="meeting-app-info-body">
                <!-- List out cards for the handouts -->
                <div class="card-deck" id="card-deck-vert-xs-full">
                  <div
                    class="card img-vert-xs"
                    v-for="handout in speaker.Handouts"
                    :key="handout.HandoutTitle"
                  >
                    <handout-card :handout="handout"></handout-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HandoutCard from "@/components/HandoutCard.vue";

export default {
  mounted() {
    this.loadSessionsAndSpeakers();
  },
  data() {
    return {
      isHandoutSessionView: true,
    };
  },
  components: {
    HandoutCard,
  },
  methods: {
    loadSessionsAndSpeakers() {
      this.$store.dispatch("loadSpeakers");
      this.$store.dispatch("loadSessions");
    },
    toggleHandoutSessionView() {
      this.isHandoutSessionView = !this.isHandoutSessionView;
    },
  },
  computed: {
    ...mapGetters(["allSessions", "allSpeakers"]),
  },
};
</script>

<style scoped>
.handout-logo {
  height: auto;
  width: auto;
  max-width: 50px;
  max-height: 50px;
}

.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
