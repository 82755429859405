<template>
  <div class="row hor-card card-sm">
    <div class="col-3 d-flex flex-column justify-content-center">
      <img
        src="@/assets/pdflogo.png"
        class="handout-logo"
        v-if="handout.HandoutTitle.trim().endsWith('.pdf')"
        alt="PDF Logo"
      />
      <img
        src="@/assets/pptxlogo.png"
        class="handout-logo"
        v-if="
          handout.HandoutTitle.trim().endsWith('.ppt') ||
          handout.HandoutTitle.trim().endsWith('.pptx')
        "
        alt="Powerpoint Logo"
      />
      <img
        src="@/assets/doclogo.png"
        class="handout-logo"
        v-if="
          handout.HandoutTitle.trim().endsWith('.doc') ||
          handout.HandoutTitle.trim().endsWith('.docx')
        "
        alt="Document Logo"
      />
    </div>
    <div class="col-9 card-body d-flex flex-column justify-content-center">
      <a
        :href="handout.HandoutURL"
        class="card-title card-title-sm"
        target="_blank"
      >
        {{ handout.HandoutTitle }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["handout"],
};
</script>
